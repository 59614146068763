import React from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { FileText } from "lucide-react";
import SEO from '../components/SEO';
import { fetchBlogPosts } from "../queries";

const fetchLatestProjects = async () => {
  const response = await fetch("/data/projects.json");
  const projects = await response.json();
  return projects.slice(0, 4);
};

const Home = () => {
  const { data: latestPosts = [] } = useQuery({
    queryKey: ["latestPosts"],
    queryFn: async () => {
      const response = await fetchBlogPosts();
      return response.data.publication.posts.edges
        .map((edge) => edge.node)
        .slice(0, 4);
    },
  });

  const { data: latestProjects = [] } = useQuery({
    queryKey: ["latestProjects"],
    queryFn: fetchLatestProjects,
  });

  const seoData = {
    title: "Home",
    description: "Welcome to Xyrus Code - Explore my latest projects and blog posts.",
    type: "website",
    url: "https://xyruscode.com.ng",
    image: "https://xyruscode.com.ng/og-image.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Xyrus Code",
      url: "https://xyruscode.com.ng",
    },
  };

  return (
    <Box>
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom>
        Welcome to My Portfolio
      </Typography>
      <Typography variant="h4" gutterBottom>
        I&apos;m a software developer based in Nigeria. I write about the code I
        write and the tech I use. I&apos;ll mostly be talking about how I built
        this site.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        startIcon={<FileText />}
        component="a"
        href="https://resume.showwcase.com/xyruscode.pdf"
        download
        sx={{ mb: 4 }}
      >
        Download Resume
      </Button>

      <Typography variant="h4" gutterBottom>
        Latest Posts
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {latestPosts.map((post) => (
          <Grid item xs={12} sm={6} md={3} key={post.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{post.title}</Typography>
              </CardContent>
              <CardActions>
                <Button size="small" component={Link} to={`/blog/${post.slug}`}>
                  Read More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button variant="outlined" component={Link} to="/blog" sx={{ mb: 4 }}>
        See More Posts
      </Button>

      <Typography variant="h4" gutterBottom>
        Latest Projects
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {latestProjects.map((project) => (
          <Grid item xs={12} sm={6} md={3} key={project.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{project.title}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  component={Link}
                  to={`/projects/${project.id}`}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button variant="outlined" component={Link} to="/projects" sx={{ mb: 4 }}>
        See More Projects
      </Button>
    </Box>
  );
};

export default Home;
