import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from "@/overrides/sentry.override";
import { Typography, Box, Container, Skeleton } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { fetchBlogPost } from '../queries';
import SEO from '../components/SEO';
import ViewCounter from '../components/ViewCounter';

const CodeBlock = ({ className, children }) => {
  const language = className ? className.replace('lang-', '') : 'javascript';
  return (
    <SyntaxHighlighter style={atomDark} language={language}>
      {children}
    </SyntaxHighlighter>
  );
};

const BlogPost = () => {
  const { slug } = useParams();

  const { data, isLoading, error } = useQuery({
    queryKey: ['blogPost', slug],
    queryFn: () => fetchBlogPost(slug || ''),
  });

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Skeleton variant="text" height={60} width="80%" />
        <Skeleton variant="text" height={20} width="40%" />
        <Skeleton variant="rectangular" height={400} />
      </Container>
    );
  }

  if (error) {
    Sentry.captureException(error);
    return <Typography color="error">Error loading blog post</Typography>;
  }

  const post = data?.data?.publication?.post;

  if (!post) {
    return <Typography>Blog post not found</Typography>;
  }

  const seoData = {
    title: post.title,
    description: post.brief,
    type: "article",
    url: `https://xyruscode.com.ng/blog/${slug}`,
    image: post.coverImage?.url || "https://xyruscode.com.ng/og-image.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": post.title,
      "image": post.coverImage?.url || "https://xyruscode.com.ng/og-image.jpg",
      "datePublished": post.dateAdded,
      "dateModified": post.dateUpdated,
      "author": {
        "@type": "Person",
        "name": "Xyrus Code"
      }
    },
  };

  return (
    <Container maxWidth="md">
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom>{post.title}</Typography>
      <Box mb={2}>
        <ViewCounter slug={slug || ''} />
      </Box>
      <Markdown
        options={{
          overrides: {
            code: {
              component: CodeBlock,
            },
          },
        }}
      >
        {post.content.markdown}
      </Markdown>
    </Container>
  );
};

export default BlogPost;