import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useQuery } from '@tanstack/react-query';
import SEO from '../components/SEO';

const fetchProducts = async () => {
  const response = await fetch('/data/products.json');
  return response.json();
};

const Store = () => {
  const { data: products, isLoading, error } = useQuery({
    queryKey: ['products'],
    queryFn: fetchProducts,
  });

  const seoData = {
    title: "Our Store",
    description: "Explore our collection of high-quality tech products and gadgets.",
    type: "website",
    url: "https://xyruscode.com.ng/store",
    image: "https://xyruscode.com.ng/images/store-og.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Store",
      "name": "Xyrus Code Store",
      "description": "High-quality tech products and gadgets",
      "url": "https://xyruscode.com.ng/store",
    },
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <SEO {...seoData} />
      <h1 className="text-3xl font-bold mb-6">Our Products</h1>
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
        <p className="font-bold">Notice:</p>
        <p>All prices listed are negotiable. Feel free to contact us for the best deal!</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product) => (
          <Card key={product.id} className="flex flex-col h-full">
            <CardHeader>
              <CardTitle className="text-xl">{product.name}</CardTitle>
            </CardHeader>
            <CardContent className="flex-grow">
              <img src={product.images[0]} alt={product.name} className="w-full h-48 object-cover mb-4 rounded-md" />
              <p className="text-lg font-semibold mb-2">₦{product.price.toLocaleString()}</p>
              <p className="text-sm text-gray-600 line-clamp-3">{product.description}</p>
            </CardContent>
            <CardFooter>
              <Link to={`/store/${product.id}`} className="w-full">
                <Button className="w-full">View Details</Button>
              </Link>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Store;