import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRouteError,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import BlogPost from "./pages/BlogPost";
import ProjectDetails from "./pages/ProjectDetails";
import Sitemap from "./pages/Sitemap";
import ErrorBoundary from "./components/ErrorBoundary";
import NotFound from "./components/NotFound";
import Guestbook from "./pages/Guestbook";
import CommentPolicy from "./pages/CommentPolicy";
import BuildInfo from "./pages/BuildInfo";
import Store from "./pages/Store";
import ProductDetails from "./pages/ProductDetails";
import * as Sentry from "@/overrides/sentry.override";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00ff00",
    },
    background: {
      default: "#000000",
      paper: "#111111",
    },
  },
});

const queryClient = new QueryClient();

const ErrorElement = () => {
  const error = useRouteError();
  Sentry.captureException(error);
  return <h1>Oops! Something went wrong.</h1>;
};

const App = () => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Router>
              <Layout>
                <Routes>
                  <Route
                    path="/"
                    element={<Home />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/about"
                    element={<About />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/projects"
                    element={<Projects />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/projects/:id"
                    element={<ProjectDetails />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/blog"
                    element={<Blog />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/blog/:slug"
                    element={<BlogPost />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/contact"
                    element={<Contact />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/guestbook"
                    element={<Guestbook />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/comment-policy"
                    element={<CommentPolicy />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/build-info"
                    element={<BuildInfo />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/store"
                    element={<Store />}
                    errorElement={<ErrorElement />}
                  />
                  <Route
                    path="/store/:id"
                    element={<ProductDetails />}
                    errorElement={<ErrorElement />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Layout>
              {/*Show sitemap outside the layout */}
              <Routes>
                <Route
                  path="/sitemap.xml"
                  element={<Sitemap />}
                  errorElement={<ErrorElement />}
                />
              </Routes>
            </Router>
          </ThemeProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
