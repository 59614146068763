import React from 'react';
import * as Sentry from "@/overrides/sentry.override";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (Sentry && typeof Sentry.captureException === 'function') {
      Sentry.captureException(error, {
        extra: {
          errorInfo: errorInfo,
        },
      });
    } else {
      console.error('Sentry not properly initialized:', error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;