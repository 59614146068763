import React from 'react';
import { Typography, Box, Grid, Card, CardContent, CardMedia, Chip, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import SEO from '../components/SEO';

const fetchProjects = async () => {
  const response = await fetch('/data/projects.json');
  return response.json();
};

const Projects = () => {
  const { data: projects = [] } = useQuery({
    queryKey: ['projects'],
    queryFn: fetchProjects,
  });

  const seoData = {
    title: "My Projects",
    description: "Explore my latest web development projects, designs, and applications.",
    type: "website",
    url: "https://xyruscode.com.ng/projects",
    image: "https://xyruscode.com.ng/images/projects-og.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      "name": "My Projects",
      "description": "A collection of web development projects and designs by Xyrus Code",
      "url": "https://xyruscode.com.ng/projects",
    },
  };

  return (
    <Box>
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom>Projects</Typography>
      <Grid container spacing={3}>
        {projects.map((project) => (
          <Grid item xs={12} sm={6} md={4} key={project.id}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              {project.type === 'design' ? (
                <iframe
                  src={project.imgSrc[0]}
                  style={{ border: '1px solid rgba(0, 0, 0, 0.1)', height: '200px' }}
                  width="100%"
                  allowFullScreen
                  title={project.title}
                />
              ) : (
                <CardMedia
                  component="img"
                  height="200"
                  image={project.imgSrc[0]}
                  alt={project.title}
                  className="mx-auto object-cover"
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" noWrap>
                  {project.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  mb: 2
                }}>
                  {project.description}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  {project.techStack.map((tech, index) => (
                    <Chip key={index} label={tech} size="small" sx={{ mr: 1, mb: 1 }} />
                  ))}
                </Box>
                <Box sx={{ mt: 'auto' }}>
                  <Button size="small" component={Link} to={`/projects/${project.id}`}>
                    Learn More
                  </Button>
                  <Button size="small" href={project.href} target="_blank" rel="noopener noreferrer">
                    {project.type === 'design' ? 'View Design' : 'Visit Project'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Projects;