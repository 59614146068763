import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Typography, Box, Grid, Card, CardContent, CardActions, Button, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import { fetchBlogPosts } from '../queries';
import * as Sentry from "@/overrides/sentry.override";

const Blog = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['blogPosts'],
    queryFn: fetchBlogPosts,
  });

  const seoData = {
    title: "Blog",
    description: "Read my latest thoughts and insights on web development and technology.",
    type: "blog",
    url: "https://xyruscode.com.ng/blog",
    image: "https://xyruscode.com.ng/images/blog-og.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Blog",
      name: "Xyrus Code Blog",
      description: "Insights and articles about web development and technology",
      url: "https://xyruscode.com.ng/blog",
    },
  };

  if (isLoading) {
    return (
      <Box>
        <SEO {...seoData} />
        <Typography variant="h2" gutterBottom>Blog Posts</Typography>
        <Grid container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Skeleton variant="rectangular" height={200} />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Skeleton variant="text" height={40} width="80%" />
                  <Skeleton variant="text" height={20} width="60%" />
                  <Skeleton variant="text" height={20} width="40%" />
                </CardContent>
                <CardActions>
                  <Skeleton variant="rectangular" width={100} height={36} />
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (error) {
    Sentry.captureException(error);
    return <Typography color="error">Error loading blog posts</Typography>;
  }

  const posts = data?.data?.publication?.posts?.edges || [];

  return (
    <Box>
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom>Blog Posts</Typography>
      <Grid container spacing={3}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post.node.url}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              {post.node.coverImage && (
                <Box sx={{ height: 200, overflow: 'hidden' }}>
                  <img
                    src={post.node.coverImage.url}
                    alt={post.node.title}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" noWrap>
                  {post.node.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  mb: 2
                }}>
                  {post.node.brief}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" component={Link} to={`/blog/${post.node.url.split('/').pop()}`}>
                  Read More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Blog;